import _ from 'lodash';
import { fx } from './fx';

export const getLocale = () => ((navigator.languages && navigator.languages.length)
  ? navigator.languages[0] : navigator.language);

export const formatCurrency = (number) => new Intl.NumberFormat(getLocale(), { style: 'currency', currency: 'EUR' }).format(number);

export const formatDate = (date) => Intl.DateTimeFormat().format(date);

export const formatFloat = (number) => number.toFixed(4);

export { fx };

export const getProductPaymentInfo = ({ start, end }, products) => {
  const startYear = Number(start.split('-')[0]);
  const endYear = Number(end.split('-')[0]);
  const allPaidYears = products.map(({ year }) => (year));

  const yearsNeeded = [
    ...Array(endYear - startYear + 1).keys(),
  ].map((i) => ((i + startYear).toString()));

  const yearsToPayFor = _.differenceWith(yearsNeeded, allPaidYears, _.isEqual);
  const yearsPaidFor = _.differenceWith(yearsNeeded, yearsToPayFor, _.isEqual);
  const needsToPay = !!yearsToPayFor.length;

  return { needsToPay, yearsPaidFor, yearsToPayFor };
};
