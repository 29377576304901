<template>
  <div class="report__step-wrap">
    <div
      class="report__step"
    >
      <h2>Choose period</h2>
      <p>Pick start and end date of report to generate.</p>

      <cv-form @submit.prevent="submit">
        <cv-date-picker
          kind="single"
          pattern="\d{4}/\d{1,2}/\d{1,2}"
          date-label="Start date"
          :cal-options="{ dateFormat: 'Y/m/d' }"
          v-model="dates.startDate"
        />

        <cv-date-picker
          kind="single"
          pattern="\d{4}/\d{1,2}/\d{1,2}"
          date-label="End date"
          :cal-options="{ dateFormat: 'Y/m/d' }"
          v-model="dates.endDate"
        />
        <cv-button
          :disabled="!canSubmit"
        >
          Next
        </cv-button>
      </cv-form>
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvDatePicker,
  CvButton,
} from '@carbon/vue';
import { startOfYear, endOfYear, format } from 'date-fns';

export default {
  name: 'ChoosePeriod',

  components: {
    CvForm,
    CvDatePicker,
    CvButton,
  },

  props: {
    value: {
      type: Object,
      default: () => ({ startDate: startOfYear(new Date()), endDate: endOfYear(new Date()) }),
    },
  },

  data() {
    return {
      dates: {
        startDate: format(this.value.startDate, 'yyyy/MM/dd'),
        endDate: format(this.value.endDate, 'yyyy/MM/dd'),
      },
    };
  },

  computed: {
    canSubmit() {
      return this.dates.startDate && this.dates.endDate;
    },
  },

  methods: {
    dateChange({ startDate, endDate }) {
      this.dates.startDate = startDate;
      this.dates.endDate = endDate;
    },
    submit() {
      const start = this.dates.startDate.replace(/\//g, '-');
      const end = this.dates.endDate.replace(/\//g, '-');
      this.$router.push(`/reports/${start}/${end}/pay`);
    },
  },

};
</script>
