import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { format } from 'date-fns';
import { formatCurrency } from '../../lib/utils';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// playground requires you to assign document definition to a variable called dd

const getData = ({ ledger }, { startDate, endDate }) => {
  const transactions = Object.values(ledger.items).flatMap((item) => (item.transactions));
  const transactionsWithinPeriod = transactions.filter(
    ({ datetime }) => (
      datetime.getTime() < new Date(endDate).getTime()
    && datetime.getTime() > new Date(startDate).getTime()
    ),
  );

  const disposals = transactionsWithinPeriod.filter(({ type }) => (type === 'SELL'));
  const profitableDisposals = disposals.filter(({ profit }) => (profit > 0));
  const lossDisposals = disposals.filter(({ profit }) => (profit < 0));

  const loss = lossDisposals.reduce((acc, { profit }) => (acc + profit), 0);
  const totalProfit = profitableDisposals.reduce((acc, { profit }) => (acc + profit), 0);

  return { disposalCount: disposals.length, loss, profit: totalProfit };
};

export const generateDocument = ({ general, ledger }, { startDate, endDate }) => {
  const summaryItem = ({ title, detail, amount }) => ({
    table: {
      widths: ['*', 'auto'],

      body: [
        [{ text: `${title}:`, style: 'bold', margin: [0, 5] }, {
          text: amount.toString(), alignment: 'right', style: 'bold', margin: [0, 5],
        }],
        [{
          text: detail, colSpan: 2, style: 'subtle', margin: [0, 5],
        }],
      ],
    },
    margin: [0, 10],
    layout: 'lightHorizontalLines',
  });

  const data = getData({ ledger }, { startDate, endDate });

  const summaryItems = [
    {
      title: 'Number of disposals',
      detail: 'Number of taxable events during the period',
      amount: data.disposalCount,
    },
    {
      title: 'Proceeds from sales',
      detail: 'This is the amount you received from all your disposals. This figure can be higher than expected if you also traded with the proceeds from previous trades',
      amount: formatCurrency(data.profit),
    },
    {
      title: 'Profits, before losses',
      detail: 'Amount of profit you made from your trades after deducting any costs',
      amount: formatCurrency(data.profit),
    },
    {
      title: 'Losses',
      detail: 'Amount of loss you made from your trades after deducting any costs',
      amount: formatCurrency(data.loss),
    },
    {
      title: 'Net gains',
      detail: 'Total gain from your trades, this is the profit minus losses',
      amount: formatCurrency(data.profit + data.loss),
    },
  ].map(summaryItem);

  return {
    pageSize: 'A4',

    content: [
      {
        text: 'tallytax.ie Tax Report\n\n',
        style: 'header1',
      },
      {
        table: {
          layout: 'noBorders',
          headerRows: 0,
          body: [
            ['For:', `${general.account.user.name} (${general.account.user.email})`],
            ['Date:', format(new Date(), 'PPpp')],
            ['Period:', `${format(new Date(startDate), 'PPP')} to ${format(new Date(endDate), 'PPP')}`],
          ],
        },
        layout: 'noBorders',
      },
      {
        table: {
          body: [[{
            text: [
              'All prices are in EUR unless stated otherwise. Capital gains have been calculated ',
              'using the FIFO IRELAND accounting method (universal tax lot for each currency). ',
              'All dates and times are in the Europe/Dublin timezone.\n\n',
              'This report may be used for tax purposes after it has been checked for accuracy and completeness by ',
              'yourself or your tax advisor.',
            ],
          }]],

        },
        margin: [0, 60],
        pageBreak: 'after',
      },
      {
        text: 'Capital gains summary\n',
        style: 'header1',
      },
      {
        text: 'Summary of your profit and loss from disposals.\n\n',
        style: 'subHeading',
      },
      ...summaryItems,
    ],
    styles: {
      header1: {
        fontSize: 25,
        bold: true,
      },
      subHeading: {
        fontSize: 16,
        color: '#888',
        bold: false,
      },
      header2: {
        fontSize: 25,
        bold: true,
      },
      bold: {
        bold: true,
      },
      subtle: {
        color: '#888',
      },
    },

  };
};

export const print = (store, { startDate, endDate }) => {
  const doc = generateDocument(store, { startDate, endDate });
  pdfMake.createPdf(doc).download('tax.lol_tax-report.pdf');
};
