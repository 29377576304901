<template>
  <div>
    <div class="content-header">
      <h1>Settings</h1>
    </div>

    <cv-tabs
      :container="false"
      aria-label="navigation tab label"
    >
      <cv-tab
        id="tab-1"
        label="Profile"
        class="settings-tab__content"
      >
        <cv-text-input
          label="Email"
          v-model="account.user.email"
          type="email"
          disabled
        />

        <cv-text-input
          label="Name"
          v-model="account.user.name"
          type="text"
          disabled
        />
      </cv-tab>

      <cv-tab
        id="tab-2"
        label="Data options"
        class="settings-tab__content"
      >
        <cv-select
          label="Base currency"
          v-model="selectedCurrency"
          disabled
        >
          <cv-select-option
            disabled
            value=""
            hidden
          >
            Choose an option
          </cv-select-option>
          <cv-select-option
            :value="currency"
            v-for="(currency) in currencyIds"
            :key="currency"
          >
            {{ currency }}
          </cv-select-option>
        </cv-select>
        <cv-button
          kind="danger"
          @click="deleteTransactions"
        >
          Delete everything
        </cv-button>
      </cv-tab>

      <cv-tab
        id="tab-3"
        label="Billing"
        class="settings-tab__content"
      >
        Sample tab panel content 2
      </cv-tab>
    </cv-tabs>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  CvTabs,
  CvTab,
  CvTextInput,
  CvButton,
  CvSelect,
  CvSelectOption,
} from '@carbon/vue';
import { currencyIds } from '@/lib/fx';
import { getPayments, updateCustomer } from '@/lib/api';

export default {
  name: 'Settings',

  components: {
    CvTabs,
    CvTab,
    CvTextInput,
    CvButton,
    CvSelect,
    CvSelectOption,
  },

  data() {
    return {
      selectedCurrency: null,
      currencyIds,
    };
  },

  computed: {
    ...mapGetters({
      account: 'general/getAccount',
      baseCurrency: 'general/getBaseCurrency',
    }),
  },

  methods: {
    async deleteTransactions() {
      const customer = await updateCustomer(this.account.customer.id, {
        transactionsDeletedFrom: new Date(),
      });
      this.$store.dispatch('general/updateCustomer', customer);
      this.$store.dispatch('ledger/calculate');
    },
  },

  async mounted() {
    this.selectedCurrency = this.baseCurrency;
    const { data: payments } = await getPayments();
    this.payments = payments;
    console.log(this.payments.filter(({ status }) => (status === 'succeeded')));
    console.log(this.payments.filter(({ status }) => (status !== 'succeeded')));
  },
};
</script>

<style lang="css">
.settings-tab__content {
  padding-top: 3rem;
}

.settings-tab__content[aria-hidden="false"] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.settings-tab__content > * {
  margin: 0 0 2rem 0;
}
</style>
