<template>
  <div class="report__step-wrap">
    <div
      class="report__step"
    >
      <h2>Download report</h2>
      <p>Hit the button below to generate and download your requested report.</p>

      <cv-form @submit.prevent="submit">
        <cv-button>
          Download
        </cv-button>
      </cv-form>
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvButton,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import { getProductPaymentInfo } from '@/lib/utils';
import { print } from '../print';

export default {
  name: 'Download',

  components: {
    CvForm,
    CvButton,
  },

  props: {
    period: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      start: this.$route.params.start,
      end: this.$route.params.end,
    };
  },

  computed: {
    ...mapGetters({
      products: 'products/getAll',
    }),
  },

  methods: {
    async submit() {
      await print(this.$store.state, { startDate: this.start, endDate: this.end });
      this.$emit('submit');
    },
  },

  mounted() {
    const { needsToPay } = getProductPaymentInfo(
      { start: this.start, end: this.end },
      this.products,
    );

    if (needsToPay) this.$router.push(`${this.$route.path.split('/').slice(0, -1).join('/')}/pay`);
  },

};
</script>
