<template>
  <div class="report__step-wrap">
    <div
      class="report__step"
    >
      <h2>Pay</h2>
      <p v-if="needsToPay">
        You need to pay to generate report for this period.
      </p>

      <p v-if="needsToPay">
        Hit next to pay.
      </p>

      <p v-if="!needsToPay">
        You have paid for the requested period. No need to pay!
      </p>

      <p v-if="!needsToPay">
        Hit next to download your report.
      </p>

      <cv-form @submit.prevent="submit">
        <cv-button
          :disabled="!canSubmit"
        >
          Next
        </cv-button>
      </cv-form>
      <cv-inline-loading
        v-if="isLoading"
        state="loading"
        loading-text="Loading..."
      />
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvButton,
  CvInlineLoading,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import { addSession } from '@/lib/api';
import { getProductPaymentInfo } from '@/lib/utils';

export default {
  name: 'ChoosePeriod',

  components: {
    CvForm,
    CvButton,
    CvInlineLoading,
  },

  props: {
    period: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      start: this.$route.params.start,
      end: this.$route.params.end,
      isLoading: true,
      yearsToPayFor: [],
      yearsPaidFor: [],
      needsToPay: true,
      url: '',
    };
  },

  computed: {
    ...mapGetters({
      products: 'products/getAll',
    }),

    canSubmit() {
      return !!this.url || !this.needsToPay;
    },
  },

  methods: {
    pay() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    submit() {
      if (this.needsToPay) {
        window.location = this.url;
      } else {
        this.$router.push(`/reports/${this.start}/${this.end}/download`);
      }
    },
  },

  async mounted() {
    const { needsToPay, yearsPaidFor, yearsToPayFor } = getProductPaymentInfo(
      { start: this.start, end: this.end },
      this.products,
    );

    this.yearsToPayFor = yearsToPayFor;
    this.yearsPaidFor = yearsPaidFor;
    this.needsToPay = needsToPay;

    if (this.needsToPay) {
      const basePath = `${this.$route.path.split('/').slice(0, -1).join('/')}/`;

      const { url } = await addSession({
        years: this.yearsToPayFor,
        successUrl: `${window.location.origin}${basePath}download`,
        cancelUrl: `${window.location.origin}/reports`,
      });
      this.url = url;
    }
    this.isLoading = false;
  },

};
</script>
