<template>
  <div>
    <div class="content-header">
      <h1>Reports</h1>
    </div>

    <choose-period
      v-if="currentStep === steps.CHOOSE_PERIOD"
      v-model="period"
      @submit="nextStep(steps.PAY_PRICE)"
    />

    <pay-price
      v-if="currentStep === steps.PAY_PRICE"
      @submit="nextStep(steps.DOWNLOAD)"
    />

    <download
      v-if="currentStep === steps.DOWNLOAD"
      :period="period"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { startOfYear, endOfYear } from 'date-fns';
import ChoosePeriod from './Steps/ChoosePeriod.vue';
import PayPrice from './Steps/PayPrice.vue';
import * as steps from './stepNames';
import Download from './Steps/Download.vue';

export default {
  name: 'Reports',

  components: {
    ChoosePeriod,
    PayPrice,
    Download,
  },

  data() {
    return {
      period: { startDate: startOfYear(new Date()), endDate: endOfYear(new Date()) },
      hasPaid: false,
      currentStep: steps.CHOOSE_PERIOD,
      steps,
    };
  },

  computed: {
    ...mapGetters({
      assets: 'ledger/getAssets',
    }),

    transactions() {
      return this.assets
        .flatMap(({ transactions }) => transactions);
    },
  },

  watch: {
    $route(to) {
      if (!to.params.action) {
        this.currentStep = steps.CHOOSE_PERIOD;
      } else if (to.params.action === 'pay') {
        this.currentStep = steps.PAY_PRICE;
      } else if (to.params.action === 'download') {
        this.currentStep = steps.DOWNLOAD;
      }
    },
  },

  methods: {
    nextStep(step) {
      this.currentStep = step;
    },
  },

  mounted() {
    if (this.$route.params.action === 'pay') {
      this.currentStep = steps.PAY_PRICE;
    }
    if (this.$route.params.action === 'download') {
      this.currentStep = steps.DOWNLOAD;
    }
  },
};
</script>

<style lang="scss">
  .report__step-wrap {
    display: flex;
  }

  .report__step {
    display: flex;
    flex-direction: column;
    width: 30rem;
    margin-right: 3rem;
    align-items: flex-start;

    h2 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .bx--form > * {
      margin-bottom: 2rem;
    }
  }

  .preset--add-button {
    flex: 0;
    align-self: flex-end;
  }

  .report__data-preview-table {
    max-width: 30rem;
  }
</style>
